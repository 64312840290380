

























































































import { Component, Watch, Prop } from "vue-property-decorator";
import { utils } from "@/utils/okta-utils";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { customerOnboardingService } from "@/services/internal-only/customer-onboarding.service";

@Component
export default class Tenant extends UserRolesMixin {
  // props
  @Prop() tenant!: any;
  @Prop() readonly!: boolean;

  // reactive class properties
  private valid = true;
  private nameRules = [
    (value: string) => !!value || "Tenant name is required",
    (value: string) => /^.*(?<!-)$/.test(value) || "Should not end with '-'",
    (value: string) => /^[a-z0-9-]+(?<!-)$/.test(value) || this.hint
  ];
  private customerIdRules = [
    (value: string) => !!value || "Customer ID is required",
    (value: string) =>
      !isNaN(parseInt(value)) || "Customer ID must be a valid integer"
  ];
  private hint = "Lower case letters, numbers and '-' only";
  private saving = false;
  private tenantAdmins: any[] = [];
  private loadingAdmins = false;
  private firstTypingDelayTimer: NodeJS.Timeout | null = null;
  private duplicateFound: any | null = null;
  private dupValidated = false;
  private utils = utils;

  // computed properties
  get o4oToken(): string {
    return this.userStore.getO4oToken;
  }
  private get title() {
    return this.tenant.id ? this.tenant.name : "New Customer";
  }
  private get isSaveButtonDeactivated(): boolean {
    return !this.valid || !this.dupValidated;
  }

  // lifecycle methods
  async created(): Promise<void> {
    await this.getTenantInfo();
  }

  // methods
  close(): void {
    this.$emit("close");
  }

  async save(): Promise<void> {
    try {
      this.saving = true;

      const tenantPayload: any = {
        name: this.tenant.name,
        customerid: parseInt(this.tenant.customerid)
      };
      const response = await customerOnboardingService.createNewTenantAsync(
        tenantPayload
      );

      this.tenant.created = response.data.created;
      this.tenant.id = response.data.id;

      this.saving = false;
      this.$emit("close");
      this.$emit("refreshTenantList");
    } catch (error) {
      this.saving = false;
    }
  }

  async listAdmins(): Promise<void> {
    this.tenantAdmins = [];
    this.loadingAdmins = true;

    if (!this.tenant.id) {
      this.loadingAdmins = false;
      return;
    }

    const apiResponse = await customerOnboardingService.listTenantAdminUsers(
      this.tenant.name
    );

    this.tenantAdmins = apiResponse.data;
    this.loadingAdmins = false;
  }

  validateSubdomainOnKeyup(): void {
    this.duplicateFound = null;
    this.dupValidated = false;

    if (this.firstTypingDelayTimer) {
      clearTimeout(this.firstTypingDelayTimer);
    }

    this.firstTypingDelayTimer = setTimeout(async () => {
      if (!this.tenant.name || this.tenant.name.length <= 0) return;

      try {
        const apiResponse = await customerOnboardingService.validateTenantSubdomain(
          this.tenant.name
        );
        if (apiResponse.data.status == 200) {
          this.duplicateFound = "Customer name already exists";
        } else {
          this.dupValidated = true;
        }
      } catch (error) {
        this.dupValidated = true;
      }
    }, 700);
  }

  async getTenantInfo(): Promise<void> {
    await this.listAdmins();
  }

  // watchers
  @Watch("tenant")
  async tenantChanged(): Promise<void> {
    if (this.tenant.id) {
      await this.getTenantInfo();
    }
  }
}
