import { app } from "@/main";
import { AxiosResponse } from "axios";

class CustomerOnboardingService {
  public async listAllTenantsAsync(next?: any): Promise<AxiosResponse<any>> {
    let requestUrl = "api/tenants";
    if (next) requestUrl += "?after=" + next;

    const apiResponse = await app.$ravenapi.get(requestUrl);
    return apiResponse.data;
  }

  public async searchTenants(search?: string): Promise<AxiosResponse<any>> {
    let requestUrl = "api/tenants";
    if (search && search?.length > 0) requestUrl += "?search=" + search;

    const apiResponse = await app.$ravenapi.get(requestUrl);
    return apiResponse.data;
  }

  public async createNewTenantAsync(newTenantPayload: {
    name: string;
  }): Promise<AxiosResponse<any>> {
    return await app.$ravenapi.post("api/tenants", newTenantPayload);
  }

  public async listTenantAdminUsers(
    tenantName: string
  ): Promise<AxiosResponse<any>> {
    const apiResponse = await app.$ravenapi.get(
      "api/tenants/" + tenantName + "/admins"
    );
    return apiResponse.data;
  }

  public async validateTenantSubdomain(
    tenantName: string
  ): Promise<AxiosResponse<any>> {
    return await app.$ravenapi.get("api/tenants/" + tenantName);
  }

  public async loadGroupStats(groupId: string): Promise<AxiosResponse<any>> {
    const endpoint = "okta/groups/" + groupId + "?expand=stats";
    return await app.$ravenapi.get(endpoint);
  }

  public async loadUsersFromOkta(
    queryParams: string
  ): Promise<AxiosResponse<any>> {
    // passing no queryParams or empty string loads all users for all tenants
    return await app.$ravenapi.get("okta/users" + queryParams);
  }

  public async loadUsersInUsersGroupFromOkta(
    tenantName: string
  ): Promise<AxiosResponse<any>> {
    const endpoint = "api/tenants/" + tenantName + "/users";
    return await app.$ravenapi.get(endpoint);
  }
}

export const customerOnboardingService = new CustomerOnboardingService();
