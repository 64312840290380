


























































































import { Component } from "vue-property-decorator";
import { AccessToken, OktaAuth } from "@okta/okta-auth-js";
import config from "@/okta-config";
import { utils } from "@/utils/okta-utils";
import Tenant from "@/components/internal-only/Tenant.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { customerOnboardingService } from "@/services/internal-only/customer-onboarding.service";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";

const authJs = new OktaAuth({
  issuer: (config.oidc.issuer as string).split("oauth2")[0],
  clientId: config.oidc.clientId,
  redirectUri: config.oidc.redirectUri,
  pkce: true,
  tokenManager: {
    autoRenew: true
  }
});

@Component({
  components: {
    Tenant
  }
})
export default class Tenants extends UserRolesMixin {
  // reactive class properties
  private tableHeaders = [
    { text: "tenant", value: "name" },
    { text: "created", value: "created" }
  ];
  private tenants: any[] = [];
  private search: string | null = "";
  private searchResults: any[] | any = [];
  private loading = false;
  private searchTyping = false;
  private typingDelayTimer: null | NodeJS.Timeout = null;
  private clickMoreOptions = false;
  private isDialogOpen = false;
  private tenant: any | null = null;
  private readonly = false;
  private next: any | null = null;
  private showNext = true;
  private utils = utils;

  // computed properties
  get o4oToken(): string {
    return this.userStore.getO4oToken;
  }

  // lifecycle methods
  async created(): Promise<void> {
    await this.initO4oToken();
    await this.getTenants();
  }

  // methods
  async initO4oToken() {
    // silently request new token with scopes to manage users and groups
    const scopesResponse = await authJs.token.getWithoutPrompt({
      scopes: [
        "openid",
        "okta.apps.manage",
        "okta.users.manage",
        "okta.groups.manage",
        "okta.idps.manage",
        "okta.roles.manage"
      ]
    });
    if (scopesResponse?.tokens?.accessToken) {
      const adminAccessToken = scopesResponse.tokens.accessToken as AccessToken;

      // store o4oToken for okta-user-management in vuex store
      this.userStore.setO4oToken(adminAccessToken.accessToken);
    } else {
      // no o4o token available, redirect to 403 page
      checkCurrentRouteAndRedirect(this.$router, "/403/access");
    }
  }

  async obtainMoreOptions(): Promise<void> {
    this.clickMoreOptions = true;
    await this.getTenants();
    this.clickMoreOptions = false;
  }

  async getTenants(): Promise<void> {
    this.loading = true;

    try {
      const apiResponse = await customerOnboardingService.listAllTenantsAsync(
        this.next
      );

      // check which tenants we already have
      const tenantIds = this.tenants.map((tenant: any) => tenant.id);
      // if the response includes another tenant, add it to tenants array
      apiResponse.data.forEach((tenant: any) => {
        if (!tenantIds.includes(tenant.id)) {
          this.tenants.push(tenant);
        }
      });

      const links = apiResponse.headers.link.split(",");
      links.forEach((link: any) => {
        const partsOfLink = link.trim().split(";");
        if (partsOfLink[1]?.trim() == "rel='next'") {
          const linkParameters = utils.urlParams(partsOfLink[0].split("?")[1]);
          this.next = linkParameters.after;
        }
      });

      if (apiResponse.data.length > 0) this.showNext = true;
    } catch (error) {
      if ((error as any).response?.status == 404) this.showNext = true;

      this.loading = false;
    }
    this.loading = false;
  }

  addTenant(): void {
    const blankTenant = {
      id: undefined,
      name: "",
      customerid: ""
    };
    this.tenant = blankTenant;
    this.tenants.unshift(this.tenant);
    this.readonly = false;
    this.isDialogOpen = true;
  }

  close(): void {
    this.isDialogOpen = false;
    if (!this.tenants[0].id) this.tenants.shift();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editItem(item: any): void {
    this.tenant = item;
    this.isDialogOpen = true;
    this.readonly = true;
  }

  async searchTenants(): Promise<void> {
    this.loading = true;

    if (this.search && this.search.length > 0) {
      this.searchResults = await customerOnboardingService.searchTenants(
        this.search
      );

      this.tenants = this.searchResults.data;
    } else {
      this.next = null;
      this.tenants = [];
      await this.getTenants();
    }
    this.loading = false;
  }

  async searchOnKeyup(): Promise<void> {
    this.searchTyping = true;
    if (this.typingDelayTimer) {
      clearTimeout(this.typingDelayTimer);
    }
    this.typingDelayTimer = setTimeout(async () => {
      await this.searchTenants();
      this.searchTyping = false;
    }, 400);
  }
}
